import React, { useEffect, useMemo } from 'react';

interface ClientSideRenderMarkProps {
  children: React.ReactNode;
  hasLoaded: boolean;
}

const ClientSideRenderMark: React.FC<ClientSideRenderMarkProps> = ({ children, hasLoaded }) => {
  useEffect(() => {
    if (typeof performance !== 'undefined' && hasLoaded) {
      performance.mark && performance.mark('pricing-plans-rendered');
    }
  }, [hasLoaded]);

  return <>{children}</>;
};

export function withClientSideRenderMark<Props>(
  Component: React.FC<Props>,
  getHasLoaded: (props: Props) => boolean = () => true,
) {
  const WrappedComponent: React.FC<Props> = (props) => {
    const hasLoaded = useMemo(() => getHasLoaded(props), [props]);
    return (
      <ClientSideRenderMark hasLoaded={hasLoaded}>
        <Component {...props} key={undefined} />
      </ClientSideRenderMark>
    );
  };

  return WrappedComponent;
}
