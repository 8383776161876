import React from 'react';
import { Button, ButtonPriority, ThreeDotsLoader } from 'wix-ui-tpa/cssVars';
import { classes, st } from './CheckoutStyledButton.st.css';

interface CheckoutStyledButtonProps {
  label: string;
  className?: string;
  loading?: boolean;
  dataHook?: string;
  disabled?: boolean;
  onClick: () => void;
  secondary?: boolean;
}

export const CheckoutStyledButton = React.forwardRef<HTMLButtonElement, CheckoutStyledButtonProps>(
  ({ label, className, loading, dataHook, disabled, onClick, secondary }, ref) => (
    <Button
      ref={ref}
      upgrade
      className={st(classes.button, { loading: !!loading, secondary: !!secondary }, className)}
      data-hook={dataHook}
      disabled={disabled}
      priority={secondary ? ButtonPriority.secondary : ButtonPriority.primary}
      onClick={onClick}
    >
      <span className={classes.label}>{label}</span>
      {loading && (
        <div className={classes.loaderContainer} data-hook={`${dataHook}-loader`}>
          <ThreeDotsLoader className={classes.loader} />
        </div>
      )}
    </Button>
  ),
);
