import React from 'react';
import { TPAComponentsProvider, useTPAComponentsContext } from 'wix-ui-tpa/cssVars';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { LocaleProvider } from '../hooks/useLocale';

export const PagesContext: React.FC<{ children: React.ReactNode; locale?: string }> = ({ children, locale }) => {
  const tpaComponentsContext = useTPAComponentsContext();
  const { isRTL } = useEnvironment();

  return (
    <TPAComponentsProvider value={tpaComponentsContext}>
      <LocaleProvider locale={locale}>
        <div dir={isRTL ? 'rtl' : 'ltr'}>{children}</div>
      </LocaleProvider>
    </TPAComponentsProvider>
  );
};
